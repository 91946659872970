<template>
    <div class="google-map new-updated-design google-map-design">
        <vue-snotify></vue-snotify>
        <div class="row">
    
            <div class="col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('bathymetry-map') }}</h4>
                    </div>
                    
                    <div class="card-body new-card-body rl-body">
                        <div class="al-btn new-al-btn"> 
                            <b-button class="ml-5 variant-orange" size="sm" @click="openSiderBar">{{ $t('total-category-counts') }}</b-button>
                            <b-button v-if="mapLoaded" class="ml-2 variant-orange" size="sm" @click="openFilters">{{ $t('filters-btn') }}</b-button>
                        </div>
                        <div class="google-map new-updated-design">
                            
                            <div class="map-container" id="mapContainer">
                            <!--    <button v-if="mapLoaded" class="filter-group" variant="info" size="sm" @click="openFilters">{{ $t('filters-btn') }}</button> -->
                                <div style="display: none;" id="coordinates">
                                </div>    
                            </div>
                            <div class="sidebar-counter">
                                <TotalCategoryCount  ref="sidebar" :totalSpots="totalSpots" user_type="admin"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-clusters" :title="$t('cluster-spots')" scrollable size="md" :hide-footer="true">
            <ClusterSpotsModal :spotsData="spotsData" @openParentSpot="openAdminSpotDetail"></ClusterSpotsModal>
        </b-modal>
        <FiltersModal @applyFilter="applyFilter" @resetMap="getClientAdminSpots" ref="filterComponent" user_type="admin"></FiltersModal>
        <SpotDetailsModal @openSpotDetail="openSpotDetail" ref="spotDetails"></SpotDetailsModal>
    </div>
</template>

<script>
    import Vue from "vue";
    import mapboxgl from "mapbox-gl";
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import API from '@/api';
    import moment from 'moment'
    import FiltersModal from "../citizen/FiltersModal.vue";
    import TotalCategoryCount from '../client/TotalCategoryCounts';
    import ClusterSpotsModal from './ClusterSpotsModal.vue';
    import SpotDetailsModal from '../citizen/SpotDetailsModal.vue';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        props: ['countableQuestions', 'total_spots'],
        data() {
            return {
                version_id:null,
                accessToken: 'pk.eyJ1Ijoid2F0Y2hzcG90dGVyIiwiYSI6ImNsMmY0OTgxdzA3MW0zYm1jMmY5MGY5OG4ifQ.vEle7r52YhgPJ8D-MVlZ2A',
                map: [],
                spotData: [],
                features: [],
                mapFeatures: [],
                spotForm: {
                    requireLatLong: 1,
                    spot_type_id: '',
                    location_id: '',
                    detail: '',
                    spot_date: '',
                    latitude: '',
                    longitude: '',
                    questions: [],
                    client_url: window.location.href.split('#')[0],
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                },
                adminMapStyle: '',
                default_map: '',
                default_map_zoom: '',
                default_latitude: '',
                default_longitude: '',
                appImage: null,
                spotDetailImages: [],
                spotDetail: [],
                spotsData: [],
                showBackButton:false,
                mapLoaded: false,
                totalSpots: 0,
                markerColor: '',
            };
        },
        components: {
            ClusterSpotsModal,
            FiltersModal,
            TotalCategoryCount,
            SpotDetailsModal
        },
        created() {
            this.$EventBus.$on("reinitializeMap", (data) => {
                this.map.remove();
                this.getClientAdminSpots();
            });
            this.$EventBus.$on("showAdminClusterSpots", (data) => {
                this.$root.$emit("bv::show::modal", "modal-clusters");
            });
        },
        mounted() {
            this.getAppSettings();
            this.getClientAdminSpots();
        },
        methods: {
            openSiderBar() {
                this.$root.$emit("bv::toggle::collapse", "sidebar-no-header");
            },
            getClientAdminSpots() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getClientAdminSpots(
                    data,
                    data => {
                        const spot_dImage = data.default_image;
                        this.totalSpots = data.total_spots;

                        data.data.forEach(data => {
                            let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                            if (!exists) {
                                const d1 = {
                                    type: "Feature",
                                    properties: {
                                        id: data.id,
                                        title: (data.spot_type) ? data.spot_type.name : '',
                                        // spot_id: data.spot_type_id,
                                        spot_id: data.spot_id,
                                        description: (data.location) ? data.location.name : '',
                                        spotted_by: (data.spotter_user) ? data.spotter_user.name : '',
                                        spotted_username: (data.spotter_user) ? data.spotter_user.user_name : '',
                                        spot_date: this.formatDate(data.spot_date),
                                        iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                        markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'
                                    },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [data.longitude, data.latitude]
                                    }
                                }
                                this.mapFeatures.push(d1);
                            }
                        })

                        // alert('working')

                        // if (navigator.geolocation) {
                        //     var self = this;
                        //     navigator.geolocation.getCurrentPosition(e => {
                        //         this.initMap([e.coords.longitude, e.coords.latitude])
                        //     }, function (error) {
                        //         if (error.code == error.PERMISSION_DENIED) {
                        //             // pop up dialog asking for location
                        //             self.initMap([self.default_longitude, self.default_latitude]);
                        //         }
                        //     })
                        // } else {
                            this.initMap([this.default_longitude, this.default_latitude]);
                        // }
                    },
                    err => {
                    }
                )
            },
            initMap(lngLat) {
                this.mapLoaded = false;
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: 'mapContainer',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: lngLat,
                    zoom: this.default_map_zoom,
                    maxZoom: 14,
                    attributionControl: false
                    // projection: 'globe'
                });

                this.spotForm.longitude = lngLat[0];
                this.longitude = lngLat[0];
                this.spotForm.latitude = lngLat[1];
                this.latitude = lngLat[1];
                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(lngLat).addTo(this.map);
    
                this.map.on('style.load', () => {
                    this.map.setFog({});
                });
                var coordinates = document.getElementById('coordinates');
                coordinates.style.display = 'block';
                coordinates.innerHTML = `Lat: ${parseFloat(this.latitude).toFixed(6)}, Lng: ${parseFloat(this.longitude).toFixed(6)}`; 
                var ref = this;
                this.marker.on('dragend', (e) => {
                    var lngLat = e.target.getLngLat();
                    coordinates.innerHTML = `Lat: ${lngLat.lat.toFixed(6)}, Lng: ${lngLat.lng.toFixed(6)}`;
                    // this.$refs.marker.mapObject.getLatLng();
                    // ref.getLatLong(lngLat)

                })

                var loadFeatures = this.mapFeatures;
                const geojson = {
                    'type': 'FeatureCollection',
                    'features': this.mapFeatures
                };

                this.map.on('load', () => {
                    
                    //adding feature data to show on map
                    this.data2 = {
                        "type": "FeatureCollection",
                        "crs": {
                            "type": "name",
                            "properties": {
                                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            }
                        },
                        "features": this.mapFeatures
                    }

                    //source to add depth layers
                    this.map.addSource('bathymetry', {
                        // 'type': 'geojson',
                        type: 'vector',
                        url: 'mapbox://mapbox.mapbox-bathymetry-v2'
                        // 'data': this.data2
                    });

                    //layer to add water depths
                    this.map.addLayer({
                        'id': 'water-depth',
                        'type': 'fill',
                        'source': 'bathymetry',
                        'source-layer': 'depth',
                        'layout': {},
                        'paint': {
                            // Transition from heatmap to circle layer by zoom level
                            // 'fill-opacity': [
                            //     'interpolate', ['linear'],
                            //     ['zoom'],
                            //     7,
                            //     0,
                            //     8,
                            //     1
                            // ],
                            // cubic bezier is a four point curve for smooth and precise styling
                            // adjust the points to change the rate and intensity of interpolation
                            'fill-color': [
                                'interpolate',
                                ['cubic-bezier', 0, 0.5, 1, 0.5],
                                ['get', 'min_depth'],
                                200,
                                '#78bced',
                                9000,
                                '#15659f'
                            ]
                        }
                    },
                    'hillshade'
                    );

                    //layer to show data on map with clusters and points
                    this.map.addSource('earthquakes', {
                        type: 'geojson',
                        data: this.data2,
                        cluster: true,
                        clusterMaxZoom: 14,
                        clusterRadius: 50
                    });
                    
                    //layer to create clusters
                    this.map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        paint: {
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#FF7F50',
                                100,
                                '#003366',
                                750,
                                '#ff414e'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });

                    //layer to count cluster points
                    this.map.addLayer({
                        id: 'cluster-count',
                        type: 'symbol',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': '{point_count_abbreviated}',
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 12,
                            "text-allow-overlap" : true
                        },
                        paint: {
                            "text-color": "#ffffff"
                        }
                    });

                    if(this.version_id === 2 || this.version_id === 3){
                        //layer to add image
                        this.map.loadImage(
                            this.appImage,
                            (error, image) => {
                            if (error) throw error;
                            // Add the image to the map style.
                            this.map.addImage('cat', image,{
                                "sdf": "true"
                            });
                        });
                        let categoryColors = [];

                        // Populate categoryColors array with actual colors
                        this.mapFeatures.forEach((feature) => {
                            categoryColors.push(feature.properties.markerColor || '');
                        });


                        this.map.addLayer({
                            id: 'unclustered-point',
                            type: 'symbol',
                            source: 'earthquakes', // reference the data source
                            filter: ['!', ['has', 'point_count']],
                            layout: {
                                "icon-image": [
                                    "match", ["get", "spot_id"],
                                    ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin", ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale", ["BS"], "baskingshark", ["UC", "UN", "OR", "SU"], "",
                                    "cat"
                                ],
                                "icon-size": 1,
                                "icon-padding": 2,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": {
                                    "base": 1,
                                    "stops": [
                                        [0, true],
                                        [12, true],
                                        [22, true]
                                    ]
                                },
                            },
                            paint: {
                                "icon-color": [
                                    "get",
                                    "icon-color"
                                ],
                                "icon-halo-blur": 0,
                                "icon-halo-color": [
                                    "get",
                                    "icon-halo-color"
                                ],
                                "icon-halo-width": 4
                            }
                        });

                        // Set the GeoJSON source data with individual colors for each feature
                        const featuresWithColors = this.mapFeatures.map((feature, index) => {
                            return {
                                ...feature,
                                properties: {
                                    ...feature.properties,
                                    'icon-color': categoryColors[index] || '',
                                    'icon-halo-color': categoryColors[index] || ''
                                }
                            };
                        });

                        this.map.getSource('earthquakes').setData({
                            type: 'FeatureCollection',
                            features: featuresWithColors
                        });
                    }
                    else{
                        //layer to add image
                        this.map.loadImage(
                            this.appImage,
                            (error, image) => {
                            if (error) throw error;
                            // Add the image to the map style.
                            this.map.addImage('cat', image);
                        });

                        //layer to add points on map
                        this.map.addLayer({
                            id: 'unclustered-point',
                            type: 'symbol',
                            source: 'earthquakes', // reference the data source
                            filter: ['!', ['has', 'point_count']],
                            // layout: {
                            //     'icon-image': 'cat', // reference the image
                            //     'icon-size': 0.25
                            // }
                            // paint: {
                            //     "text-color": "#d21010",
                            //     "icon-halo-color": "#d21010",
                            //     "icon-halo-width": 2
                            // },
                            layout: {
                                "icon-image": [
                                    "match",
                                    ["get", "spot_id"],
                                    ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin",
                                    ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale",
                                    ["BS"], "baskingshark",
                                    ["UC", "UN", "OR", "SU"], "",
                                    "cat"
                                ],
                                "icon-padding": 2,
                                // 'icon-size': 0.05,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": {
                                    "base": 1,
                                    "stops": [
                                        [0, true],
                                        [12, true],
                                        [22, true]
                                    ]
                                },
                            }
                        });
                    }

                    this.map.on('click', 'clusters', (e) => {
                        const features = this.map.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        var clusterId = features[0].properties.cluster_id;
                        var zoom = this.map.getZoom();
                        if(zoom < 14) {
                            this.map.getSource('earthquakes').getClusterExpansionZoom(
                                clusterId,
                                (err, zoom) => {
                                    if (err) return;
                                    this.map.easeTo({
                                        center: features[0].geometry.coordinates,
                                        zoom: zoom
                                    });
                                }
                            );
                        } else {
                            var point_count = e.features[0].properties.point_count;
                            var clusterSource = this.map.getSource("earthquakes");

                            clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
                                this.spotsData = aFeatures;
                                this.spotsData.sort(function(a,b) {
                                    return new Date(b.properties.spot_date) - new Date(a.properties.spot_date);
                                });
                                this.openClusterSpotDetail(this.spotsData);
                            });
                        }
                    });

                    // if(this.version_id === 2 || this.version_id === 3){
                    //     this.mapFeatures.forEach((feature) => {
                    //         this.map.on('click', `unclustered-point-${feature.properties.id}`, (e) => {
                    //             const coordinates = e.features[0].geometry.coordinates.slice();
                    //             const spot_type = e.features[0].properties.title;
                    //             const id = e.features[0].properties.id;
                    //             const spot_id = e.features[0].properties.spot_id;
                    //             const iconUrl = e.features[0].properties.iconUrl;
                    //             const spotted_by = e.features[0].properties.spotted_by;
                    //             const spotted_username = e.features[0].properties.spotted_username;
                    //             const spot_date = e.features[0].properties.spot_date;
                    //             const description = e.features[0].properties.description;
                    //             while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    //                 coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    //             }
                    //             // this.openSpotDetail(id,false);
                    //             this.$refs.spotDetails.openAdminSpotDetail(id, false);
                    //         });
                    //     });

                    //     this.map.on('mouseenter', 'clusters', () => {
                    //         this.map.getCanvas().style.cursor = 'pointer';
                    //     });
                    //     this.map.on('mouseleave', 'clusters', () => {
                    //         this.map.getCanvas().style.cursor = '';
                    //     });
                    // }
                    // else{
                        this.map.on('click', 'unclustered-point', (e) => {
                            const coordinates = e.features[0].geometry.coordinates.slice();
                            const spot_type = e.features[0].properties.title;
                            const id = e.features[0].properties.id;
                            const spot_id = e.features[0].properties.spot_id;
                            const iconUrl = e.features[0].properties.iconUrl;
                            const spotted_by = e.features[0].properties.spotted_by;
                            const spotted_username = e.features[0].properties.spotted_username;
                            const spot_date = e.features[0].properties.spot_date;
                            const description = e.features[0].properties.description;
                            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                            }
                            // this.openSpotDetail(id,false);
                            this.$refs.spotDetails.openAdminSpotDetail(id, false);
                        });

                        this.map.on('mouseenter', 'clusters', () => {
                            this.map.getCanvas().style.cursor = 'pointer';
                        });
                        this.map.on('mouseleave', 'clusters', () => {
                            this.map.getCanvas().style.cursor = '';
                        });
                    // }
                    this.mapLoaded = true;
                    // this.map.on('click', 'bathymetry-point', (e) => {
                    //     alert(e)
                    // })    
                });
                const logo = document.querySelector('.mapboxgl-ctrl-logo');
                if (logo) {
                    logo.remove();
                }
            },
            openAdminSpotDetail(id,event) {
                this.$refs.spotDetails.openAdminSpotDetail(id,true)
            },
            openFilters() {
                this.$root.$emit("bv::show::modal", "modal-map-filters");
                // this.$refs.filterComponent.getFiltersData();
            },
            applyFilter(params) {
                let data = {
                    spot_id: params.spotId,
                    date_from: params.dateFrom,
                    date_to: params.dateTo,
                    questions: params.questions,
                    categories: params.categories,
                    locations: params.locations,
                    surveys: params.surveys,
                    spotTypes: params.spotTypes,
                    selectedQuestions: params.selectedQuestions,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    countRangeFilter: params.countRangeFilter,
                    countRangeValues: params.countRangeValues,
                }
                console.log('filter data', data);
                API.getSpotsByAdminFilters(data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            this.data2.features = [];
                            this.mapFeatures = [];
                            this.totalSpots = data.total_spots;
                            this.$refs.sidebar.assignQuestions(data.countableQuestions);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                            this.$refs.filterComponent.changeSliderValue(data.range);
                            const spot_dImage = data.default_image;
                            data.data.forEach(data => {
                                console.log('spot', data);
                                let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                                if (!exists) {
                                    const d1 = {
                                        type: "Feature",
                                        properties: {
                                            id: data.id,
                                            title: (data.spot_type) ? data.spot_type.name : '',
                                            // spot_id: data.spot_type_id,
                                            spot_id: data.spot_id,
                                            description: (data.location) ? data.location.name : '',
                                            spotted_by: (data.spotter_user && data.spotter_user.name) ? data.spotter_user.name : '',
                                            spotted_username: (data.spotter_user && data.spotter_user.user_name) ? data.spotter_user.user_name : '',
                                            spot_date: this.formatDate(data.spot_date),
                                            iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                            markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'
                                        },
                                        geometry: {
                                            type: "Point",
                                            coordinates: [data.longitude, data.latitude]
                                        }
                                    }
                                    this.mapFeatures.push(d1);
                                }
                            })
                            let categoryColors = [];

                            this.mapFeatures.forEach((feature) => {
                                // Collect all category colors
                                categoryColors.push(feature.properties.markerColor || '');
                            });
                            // Set the GeoJSON source data with individual colors for each feature
                            const featuresWithColors = this.mapFeatures.map((feature, index) => {
                                return {
                                    ...feature,
                                    properties: {
                                        ...feature.properties,
                                        'icon-color': categoryColors[index] || '',
                                        'icon-halo-color': categoryColors[index] || ''
                                    }
                                };
                            });

                            this.map.getSource('earthquakes').setData({
                                type: 'FeatureCollection',
                                features: featuresWithColors
                            });
                            // this.data2 = {
                            //     "type": "FeatureCollection",
                            //     "crs": {
                            //         "type": "name",
                            //         "properties": {
                            //             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            //         }
                            //     },
                            //     "features": this.mapFeatures
                            // }
                            // this.map.getSource("earthquakes").setData(this.data2);
                            console.log('applyFilterresponse', data)
                        } else if (data.status === 500) {
                            this.$snotify.error(data.message);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                        }
                    },
                    err => {
                        console.log(err);
                        this.$refs.filterComponent.StopFilterLoading();
                        this.$refs.filterComponent.StopResetLoading();
                    }
                );
            },
            openClusterSpotDetail(aFeatures) {
                this.$EventBus.$emit('showAdminClusterSpots');
            },
            formatDate(date) {
                return moment(String(date)).format('MM-DD-YYYY HH:mm')
            },
    
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.version_id = data.version_id;
                        console.log('this.version_id :', this.version_id);
                        this.appImage = this.$server_url + data.data.stock_image;
                        // this.adminMapStyle = data.data.default_map;
                        // this.default_map = data.data.default_map;
                        this.default_map_zoom = data.data.default_map_zoom;
                        this.default_latitude = (data.data.latitude) ? data.data.latitude : 39.452717;
                        this.default_longitude = (data.data.longitude) ? data.data.longitude : -123.813866;
    
                    },
                    err => {}
                )
            },
        }
    };
</script>

<style scoped>
    #coordinates {
        position: absolute !important;
        bottom: 10px !important;
    }
    .basemap {
        width: 100%;
        height: 100%;
    }
    
    .mapboxgl-canvas {
        width: 1200px;
        height: 689.15px;
        position: static !important;
    }
    
    .mapboxgl-canvas-container {
        position: static !important;
        width: 1200px !important;
    }
    
    #menu {
        position: absolute;
        background: #efefef;
        padding: 10px;
        font-family: 'Open Sans', sans-serif;
        z-index: 1;
    }
    .google-map-design {
        min-height: calc(100vh - 184px);
        height: 100%;
    }
    .google-map-design #mapContainer {
        height: calc(100vh - 285px);
    }
    .rl-body {
        position: relative;
    }
    .al-btn {
        position: absolute;
        left: -10px;
        z-index: 5;
        top: 30px;
    }
    .al-btn button {
        color:#fff !important;
    }
    .al-btn button:hover {
        color:#fff !important;
    }
    .variant-orange {
        color: #fff !important;
        background-color: #fa6400;
        border-color: #fa6400;
    }
    .new-table-responsive  tr td span {
        white-space: pre-wrap;
        line-height: 1.2;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
        .new-al-btn {
            left: 0px;
        }
        .new-al-btn button:nth-child(1) {
            margin-left: 28px !important;
            margin-right: 0px !important;
        }
        .new-al-btn button:nth-child(2) {
            margin-left: 5px !important;
        }
    }
</style>
